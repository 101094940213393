/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import PropTypes from 'prop-types';
import './FullImageSize.css';

const FullImageSize = ({ url }) => {

    if (Array.isArray(url)) {
        return (
            <div className='image-container'>
                {url.map((image, index) => (
                    <img src={image.amenitieImg} key={index} alt={image.name || `image-${index}`} />
                ))}
            </div>
        );
    } else {
        return (
            <div className='image-container'>
                <img src={url} alt="full-screen" />
            </div>
        );
    }
};

FullImageSize.propTypes = {
    url: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.shape({
                amenitieImg: PropTypes.string.isRequired,
                name: PropTypes.string
            })
        )
    ]).isRequired
};

export default FullImageSize;