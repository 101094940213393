import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { getStyleObjectFromString, formatStyles } from "../../../../plugins/FormatStyle";
const IframeLightbox = ({ iframeSrc, buttonText, stylesBtn, className, typeStyle }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = ""; // Asegura el estado original al desmontar
    };
  }, [isOpen, stylesBtn]);

  const ModalContent = () => (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-hidden"
      onClick={() => setIsOpen(false)}
    >
      {/* Contenedor del iframe */}
      <div
        className="bg-white rounded-lg shadow-lg w-[80vw] h-[80vh] relative"
        onClick={(e) => e.stopPropagation()} // Evita cerrar al hacer clic dentro
      >
        {/* Botón de cierre agrandado */}
        <button
          onClick={() => setIsOpen(false)}
          className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-12 h-12 text-xl flex items-center justify-center hover:bg-red-600"
        >
          ×
        </button>

        {/* Contenido del iframe */}
        <div className="w-full h-full">
          <iframe
            src={iframeSrc}
            title="Iframe Lightbox"
            className="w-full h-full"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* Botón para abrir el lightbox */}
      <button
        onClick={() => setIsOpen(true)}
        className={`${className}`}
        style={ typeStyle !== "format" ? {...getStyleObjectFromString(stylesBtn)} : {...formatStyles(stylesBtn)}}
      >
        {buttonText}
      </button>

      {/* Renderizar el modal usando un portal */}
      {isOpen && ReactDOM.createPortal(<ModalContent />, document.body)}
    </>
  );
};

export default IframeLightbox;
