/* eslint-disable no-eval */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useParams } from "react-router-dom";
import WorkSheetList from "./WorkSheetList";
import DualRangeSlider from "../../../Templates/_Parts/DualRange";
import { filterData, SearchType } from "filter-data";
import { saveOrUpdateProject } from "../../../../db";
import "../_styles/FilterBar.css";
import FloatMenu from "../../_Parts/FloatMenu";

const FilterBar = forwardRef(
  (
    {
      showFavs,
      handleFavsClick,
      floorplans,
      favorites,
      worksheets,
      toggleWorksheets,
      setFloorplans,
      units,
      sketchStyle,
      masterSketchRef,
      sketchSetup,
      updateBuildBoxes,
      resetBoxes,
      isTablet,
      isLandscape,
      setFloorsFilter,
      stylesProject,
      isPricing,
      hideSold,
      isRent,
      isFloorplanPage,
    },
    ref
  ) => {
    const { projectId } = useParams();
    const [worksheetsList, setWorksheetsList] = useState(false);
    const [unitsAvailables, setUnitsAvailables] = useState([]);
    const [unitsSold, setUnitsSold] = useState([]);
    const [initFloorplans, setInitFloorplans] = useState([]);
    const [filteredFloorplans, setFilteredFloorplans] = useState([]);
    const [typeFilter, setUniq] = useState([]);
    const [oriFilter, setOriFilter] = useState("");
    const [floorFilter, setFloorFilter] = useState("");
    const [bathFilter, setBathFilter] = useState("");
    const [filterType, setFilterType] = useState([]);
    const [filterBath, setFilterBath] = useState([]);
    const [filterOrientation, setFilterOrientation] = useState([]);
    const [filterFloors, setFilterFloor] = useState([]);
    const [floorsFiltered, setFloorsFiltered] = useState([]);
    const [minArea, setMinArea] = useState(0);
    const [minFloor, setMinFloor] = useState(0);
    const [minSize, setMinSize] = useState(0);
    const [maxArea, setMaxArea] = useState(0);
    const [minFloorSize, setMinFloorSize] = useState(0);
    const [maxFloor, setMaxFloor] = useState(0);
    const [maxFloorSize, setMaxFloorSize] = useState(0);
    const [maxSize, setMaxSize] = useState(0);
    const [showFilters, setShowFilters] = useState(false);
    const [initFloors, setInitFloors] = useState([]);
    const [selectedNames, setSelectedNames] = useState("");
    const [rotations, setRotations] = useState({});
    const [filterBarVisible, setFilterBarVisible] = useState(true);
    const [isMasterSketchReady, setIsMasterSketchReady] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);

    const sliderRef = useRef();

    useEffect(() => {
      const checkInterval = setInterval(() => {
        if (
          masterSketchRef.current &&
          typeof masterSketchRef.current.displayModelAvailability === "function"
        ) {
          setIsMasterSketchReady(true);
          clearInterval(checkInterval);
        }
      }, 100);
      return () => clearInterval(checkInterval);
    }, [masterSketchRef, resetBoxes, isMasterSketchReady]);

    const generateFloorRange = (min, max) => {
      return Array.from({ length: max - min + 1 }, (_, i) => i + min);
    };

    useEffect(() => {
      if (floorplans && floorplans.length > 0) {
        let searchConditions = [
          {
            key: "fp_name",
            value: selectedNames,
            type: selectedNames !== "" ? SearchType.LK : SearchType.NE,
          },
          {
            key: "fp_size",
            value: minArea,
            type: SearchType.GTE,
          },
          {
            key: "fp_size",
            value: maxArea,
            type: SearchType.LTE,
          },
          {
            key: "fp_bathrooms",
            value: bathFilter,
            type: bathFilter !== "" ? SearchType.LK : SearchType.NE,
          },
          {
            key: "fp_orientations.fp_orientation",
            value: oriFilter,
            type: oriFilter !== "" ? SearchType.EQ : SearchType.NLK,
          },
        ];

        const filtered = filterData(initFloorplans, searchConditions);

        let logicType =
          filterType.length > 0
            ? filtered.filter((item) =>
                filterType.includes(item.fp_types.fp_type_each.fp_type)
              )
            : filtered;

        setFilteredFloorplans(logicType);
        setFloorplans(logicType);
        refreshBuildBoxes(logicType);
      }
    }, [
      filterType,
      filterBath,
      minArea,
      maxArea,
      minFloor,
      maxFloor,
      initFloorplans,
      oriFilter,
      bathFilter,
      selectedNames,
      openSearch,
    ]);

    useEffect(() => {}, [
      filterType,
      minArea,
      maxArea,
      minFloor,
      maxFloor,
      oriFilter,
      floorsFiltered,
      floorFilter,
      filteredFloorplans,
    ]);

    /**
     * Toggles the visibility of the filter bar.
     *
     * This function switches the state of `filterBarVisible` between `true` and `false`.
     */
    const toggleFilterBar = () => {
      setFilterBarVisible(!filterBarVisible);
    };

    /**
     * Handles the change event for a select input element and updates the selected names.
     *
     * @param {Event} e - The change event triggered by the select input element.
     */
    const handleNameChange = (e) => {
      console.log(e);
      setSelectedNames(e);
      setRotations("");
    };

    /**
     * Toggles the rotation state of a given item.
     *
     * @param {string} itemName - The name of the item to toggle rotation for.
     */
    const handleRotateClick = (itemName) => {
      setRotations(itemName === rotations ? "" : itemName);
    };

    /**
     * Handles the change event for the bedroom filter.
     *
     * @param {Event} e - The event object from the change event.
     * @returns {Promise<void>} A promise that resolves when the filter type is set.
     */
    const handleBedroomChange = (e) => {
      setFilterType((prev) => {
        if (prev.includes(e)) {
          return prev.filter((item) => item !== e);
        } else {
          return [...prev, e];
        }
      });
    };

    /**
     * Handles the change in range values for the filter.
     *
     * @param {Object} range - The range object containing min and max values.
     * @param {number} range.min - The minimum value of the range.
     * @param {number} range.max - The maximum value of the range.
     */
    const handleRangeChange = ({ min, max }) => {
      setMinArea(min);
      setMaxArea(max);
    };

    /**
     * Handles the change in floor range selection.
     *
     * @param {Object} param - The floor range object.
     * @param {number} param.min - The minimum floor value.
     * @param {number} param.max - The maximum floor value.
     */
    const handleRangeFloorChange = ({ min, max }) => {
      setMinFloor(min);
      setMaxFloor(max);

      const range = generateFloorRange(min, max);
      setFloorsFilter(range);
      setFloorsFiltered(range);

      saveOrUpdateProject({
        id: projectId,
        range_floors_filter: range,
      });
    };

    useEffect(() => {
      const range = generateFloorRange(minFloor, maxFloor);
      const filteredByFloor = initFloorplans.filter((fp) =>
        fp.fp_floors?.fp_floor?.some((floor) => range.includes(floor))
      );

      setFilteredFloorplans(filteredByFloor);
      setFloorplans(filteredByFloor);
    }, [minFloor, maxFloor, initFloorplans]);

    /**
     * The `handleReset` function resets various state variables and updates floor units based on
     * availability.
     */
    const handleReset = async () => {
      if (sliderRef.current) sliderRef.current.resetSlider();

      const resetValues = {
        minArea: minSize,
        minSize,
        minFloorSize,
        maxFloorSize,
        maxArea: maxSize,
        maxSize,
        minFloor: minFloorSize,
        maxFloor: maxFloorSize,
        floorsFilter: initFloors,
        floorsFiltered: initFloors,
        filterType: "",
        oriFilter: "",
        bathFilter: "",
        selectedNames: "",
        filterBath: "",
        floorplans: initFloorplans,
        filteredFloorplans: initFloorplans,
      };

      Object.entries(resetValues).forEach(([key, value]) => {
        eval(`set${key.charAt(0).toUpperCase() + key.slice(1)}(value)`);
      });

      saveOrUpdateProject({
        id: projectId,
        range_floors_filter: initFloors,
      });

      setRotations("");

      setTimeout(async () => {
        const availableUnits = units
          .filter((unit) => unit.unit_status === "available")
          .map((u) => u.unit_model_name);
        const soldUnits =
          hideSold === "1"
            ? []
            : units
                .filter((unit) => unit.unit_status !== "available")
                .map((u) => u.unit_model_name);
        await updateBuildBoxes(availableUnits, soldUnits, sketchStyle);
      }, 200);
    };

    /**
     * Refreshes the build boxes based on the provided filter.
     *
     * @param {Array} filter - The filter criteria to apply.
     * @returns {Promise<void>} A promise that resolves when the build boxes have been updated.
     *
     * @async
     */
    const refreshBuildBoxes = async (filter) => {
      if (filter.length === 0) {
        setShowFilters(false);
        if (isMasterSketchReady) {
          updateBuildBoxes([], [], sketchStyle);
        }
        return;
      }

      /**
       * Filters the units based on the provided criteria.
       *
       * @param {boolean} isAvailable - Indicates whether to filter for available units.
       * @returns {string[]} - An array of unit model names that match the filter criteria.
       */
      const filterUnits = (isAvailable) =>
        units
          .filter((unit) =>
            filter.some((fp) => {
              const matchesFloorPlan = fp.fp_id === unit.fp_id;
              const matchesStatus = isAvailable
                ? unit.unit_status === "available"
                : unit.unit_status !== "available";
              const matchesOrientation =
                !oriFilter || unit.unit_orientation === oriFilter;
              const matchesFloor =
                !floorsFiltered || floorsFiltered.includes(unit.unit_floor);
              return (
                matchesFloorPlan &&
                matchesStatus &&
                matchesOrientation &&
                matchesFloor
              );
            })
          )
          .map((unit) => unit.unit_model_name);
      const filteredUnitsAvailable = filterUnits(true);
      const filteredUnitsSold = filterUnits(false);

      setUnitsAvailables(filteredUnitsAvailable);
      setUnitsSold(filteredUnitsSold);

      await updateBuildBoxes(
        filteredUnitsAvailable,
        hideSold === "1" ? [] : filteredUnitsSold,
        sketchStyle,
        floorFilter
      );

      if (isMasterSketchReady) {
        const orientationCam =
          oriFilter ||
          units.find((unit) => unit.fp_id === filter[0].fp_id).unit_orientation;
        await masterSketchRef.current.sketchRotateCamera(
          sketchSetup.building_name,
          sketchSetup.orientation_camera[sketchSetup.building_name].orientation[
            orientationCam
          ]
        );
      }
    };

    useEffect(() => {
      const handleClick = async (event) => {
        const closeButton = event.target.closest("#close-floorplan");
        if (closeButton && filteredFloorplans.length < initFloorplans.length) {
          await updateBuildBoxes(
            unitsAvailables,
            hideSold === "1" ? [] : unitsSold,
            sketchStyle
          );
        }
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, [unitsAvailables, unitsSold, sketchStyle, oriFilter]);

    useEffect(() => {
      if (floorplans.length > 0 && initFloorplans.length === 0) {
        const uniq = Array.from(
          new Set(floorplans.map((fp) => fp.fp_types.fp_type_each.fp_type))
        );
        const baths = Array.from(
          new Set(floorplans.map((fp) => fp.fp_bathrooms))
        );
        const orientation = Array.from(
          new Set(floorplans.flatMap((fp) => fp.fp_orientations.fp_orientation))
        );
        const floors = Array.from(
          new Set(floorplans.flatMap((fp) => fp.fp_floors.fp_floor))
        );

        if (filterFloors.length === 0) {
          setFloorsFilter(floors);
          setInitFloors(floors);
          setFloorsFiltered(floors);
        }

        setFilterFloor(floors);
        setFilterOrientation(orientation);
        setFilterBath(baths);
        setInitFloorplans(floorplans);
        setUniq(uniq);

        if (floorplans.length > 0) {
          const sizes = floorplans.map((fp) => fp.fp_size);
          const maxFloorplanSize = Math.max(...sizes);
          const minFloorplanSize = Math.min(...sizes);

          setMaxSize(maxFloorplanSize);
          setMaxArea(maxFloorplanSize);
          setMinArea(minFloorplanSize);
          setMinSize(minFloorplanSize - 10);

          const maxFloorSize = Math.max(...floors);
          const minFloorSize = Math.min(...floors);

          setMaxFloorSize(maxFloorSize);
          setMaxFloor(maxFloorSize);
          setMinFloor(minFloorSize);
          setMinFloorSize(minFloorSize);
        }
      }
    }, [floorplans, initFloorplans, filterFloors]);

    useImperativeHandle(ref, () => ({
      handleReset,
      oriFilter,
    }));

    return (
      <>
        <FloatMenu
          favorites={favorites}
          isRent={isRent}
          handleFavsClick={handleFavsClick}
          isFloorplanPage={isFloorplanPage}
          setOpenSearch={setOpenSearch}
          openSearch={openSearch}
        />

        <div
          id="search"
          className={!openSearch ? "visible" : ""}
          style={!isRent ? { right: "167px" } : { right: "105px" }}
        >
          <div id="bgMobileSearch"></div>
          <div id="searchInner">
            <div
              className="searchCol"
              id="searchColBuilding"
              style={{
                display: "none",
                ...(!openSearch ? { opacity: 1 } : { opacity: 0 }),
              }}
            >
              <div className="searchColTitle">
                <div className="searchColTitleInner contentFont">Building</div>
              </div>
              <div className="searchColContent" id="searchForBuilding"></div>
              <div id="searchForBuildMobile">
                <select
                  className="dropdown searchDropdown"
                  id="searchForBuildDropdown"
                  onchange="changeBuilding(this.value)"
                ></select>
              </div>
            </div>

            <div
              className={`searchCol ${rotations === "byTape" ? "active" : ""}`}
              id="searchColType"
              onClick={() => handleRotateClick("byTape")}
              style={{
                display: "block",
                ...(!openSearch ? { opacity: 1 } : { opacity: 0 }),
              }}
            >
              <div className="searchColTitle">
                <div className="searchColTitleInner contentFont">Type</div>
              </div>
              <div
                className="searchColContent"
                id="searchForType"
                style={
                  rotations === "byTape"
                    ? { display: "block", opacity: 1 }
                    : { display: "none", opacity: 0 }
                }
              >
                {typeFilter &&
                  typeFilter.map((bedroom) => (
                    <div
                      className="searchCatDiv searchCol2"
                      onChange={() => handleBedroomChange(bedroom)}
                    >
                      <label className="customCheckbox" key={bedroom}>
                        <input
                          type="checkbox"
                          className="checkbox filterCheckbox"
                          data-name={bedroom}
                        />
                        <span className={`checkboxItem slug_${bedroom}`}></span>
                        <span className="checkboxContent">{bedroom}</span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`searchCol ${rotations === "byMore" ? "active" : ""}`}
              id="searchColMore"
              style={{
                display: "block",
                ...(!openSearch ? { opacity: 1 } : { opacity: 0 }),
              }}
            >
              <div
                className="searchColTitle invMobile"
                onClick={() => handleRotateClick("byMore")}
              >
                <div className="searchColTitleInner contentFont">
                  More Filters
                </div>
              </div>
              <div
                className="searchColContent"
                id="searchForMore"
                style={
                  rotations === "byMore"
                    ? { display: "block", opacity: 1 }
                    : { display: "none", opacity: 0 }
                }
              >
                <div
                  className="rangeContainer previewFloorplanDropdown"
                  id="priceRangeContainer"
                >
                  <div className="rangeLabel">Price</div>
                  <div className="rangeLabel">AREA (SQ.FT.)</div>
                  <div className="calcRange mb-3">
                    <DualRangeSlider
                      ref={sliderRef}
                      min={minSize}
                      max={maxSize}
                      onMouseUp={({ min, max }) => {
                        handleRangeChange({ min, max });
                      }}
                      minVal={minArea}
                      maxVal={maxArea}
                      colors={stylesProject.searchCol?.color_active}
                    />
                  </div>
                </div>
                <div className="searchCatDiv catDivHalfMobile catDivHalfMobileFirst">
                  <div className="rangeLabel">Bathrooms</div>
                  <select
                    className="dropdown searchDropdown"
                    id="bathroomsSelect"
                    onChange={(e) => setBathFilter(e.target.value)}
                    value={bathFilter}
                  >
                    <option value="">ALL</option>
                    {filterBath &&
                      filterBath.map((bath) => (
                        <option key={bath} value={bath}>
                          {bath} BATH
                        </option>
                      ))}
                  </select>
                </div>
                <div className="searchCatDiv catDivHalfMobile catDivHalfMobileSecond">
                  <div className="rangeLabel">Orientation</div>
                  <select
                    className="dropdown searchDropdown"
                    id="orientationsSelect"
                    onChange={(e) => setOriFilter(e.target.value)}
                    value={oriFilter}
                    style={{
                      color: stylesProject.searchCol?.color_active,
                      backgroundColor:
                        stylesProject.searchCol?.["background-color_active"],
                    }}
                  >
                    <option value="">ALL</option>
                    {filterOrientation &&
                      filterOrientation.map((orientation) => (
                        <option key={orientation} value={orientation}>
                          {orientation}
                        </option>
                      ))}
                  </select>
                </div>
                <div
                  className="rangeContainer searchCatDiv"
                  id="floorRangeContainer"
                >
                  <div className="rangeLabel">Floor</div>
                  <div className="calcRange">
                    <DualRangeSlider
                      ref={sliderRef}
                      min={minFloorSize}
                      max={maxFloorSize}
                      onMouseUp={({ min, max }) => {
                        handleRangeFloorChange({ min, max });
                      }}
                      minVal={minFloor}
                      maxVal={maxFloor}
                      colors={stylesProject.searchCol?.color_active}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`searchCol ${rotations === "byName" ? "active" : ""}`}
              style={{
                display: "block",
                ...(!openSearch ? { opacity: 1 } : { opacity: 0 }),
              }}
            >
              <div
                className="searchColTitle"
                onClick={() => handleRotateClick("byName")}
              >
                <div className="searchColTitleInner contentFont">By Name</div>
              </div>
              <div
                className="searchColContent"
                id="searchForName"
                style={{
                  ...(rotations === "byName"
                    ? { display: "block", opacity: 1 }
                    : { display: "none", opacity: 0 }),
                  maxHeight: "498px",
                }}
              >
                {initFloorplans &&
                  initFloorplans.map((fp) => (
                    <div
                      className="searchCatDiv"
                      style={{ color: stylesProject.searchCol?.color_active }}
                      onClick={() => handleNameChange(fp.fp_name)}
                    >
                      {fp.fp_name}
                    </div>
                  ))}
              </div>

              {/* <div id="searchForNameMobile">
               <select
                 className="dropdown searchDropdown"
                 id="searchForNameDropdown"
                 onchange="previewFloorplanDropdown(this.value)"
               >
                 <option value="" selected="">
                   Select a Floor Plan
                 </option>
                 <option value="10034">The Bleeker</option>
                 <option value="10005">The Bowery</option>
                 <option value="10040">The Carnegie</option>
                 <option value="10045">The Chelsea</option>
                 <option value="10044">The Claremont</option>
                 <option value="10037">The Columbia</option>
                 <option value="10048">The Greenwich</option>
                 <option value="10036">The Houston</option>
                 <option value="10041">The Kennedy</option>
                 <option value="10047">The Liberty</option>
                 <option value="10033">The Marcy</option>
                 <option value="10035">The Melrose</option>
                 <option value="10046">The Plaza</option>
                 <option value="10038">The Rivington</option>
                 <option value="10042">The Thompson</option>
                 <option value="10039">The Tribeca</option>
                 <option value="10043">The Walker</option>
                 <option value="10049">The Washington</option>
               </select>
             </div> */}
            </div>

            <div
              className="searchCol"
              id="resetSearch"
              style={{
                display: "block",
                ...(!openSearch ? { opacity: 1 } : { opacity: 0 }),
              }}
            >
              <div
                className="searchColTitle"
                onClick={() => {
                  handleReset();
                }}
              >
                <div className="searchColTitleInner contentFont">Reset</div>
              </div>
            </div>
          </div>

          <div id="searchBtnsMobile">
            <div id="searchBtnMobileReset" className="searchBtnMobile">
              Reset
            </div>
            <div id="searchBtnMobileApply" className="searchBtnMobile">
              Apply Filters
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default FilterBar;
