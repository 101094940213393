/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import slugify from "react-slugify";

import "./FloatMenu.css";

const FloatMenu = ({
    favorites = [],
    isRent,
    handleFavsClick,
    isFloorplanPage,
    setOpenSearch,
    openSearch,
    floorplanPage,
}) => {
    const [openFavorites, setOpenFavorites] = useState(false);
    const navigate = useNavigate();
    const { projectId } = useParams();

    useEffect(() => {}, [favorites, openSearch, setOpenSearch, floorplanPage]);

    const handleFavoritesClick = () => {
        handleFavsClick ? handleFavsClick() : navigateToFloorplan();
        setOpenFavorites(!openFavorites);
    };

    const navigateToFloorplan = () => {
        navigate(`/project/${projectId}/${slugify(floorplanPage.title)}`);
    };

    const handleSearchClick = () => {
        if (!isFloorplanPage) {
            navigateToFloorplan();
        } else {
            setOpenSearch(!openSearch);
        }
    };

    return (
        <div id="secMenu">
            <div
                id="favsIcon"
                className={`secIcon ${openFavorites ? "active" : ""}`}
                onClick={handleFavoritesClick}
            >
                <div id="favsCount">{favorites.length}</div>
            </div>

            {!isRent && (
                <div id="quickAddWs" className="secIcon">
                    <div id="wsCount">0</div>
                    <div id="quickAddContainer" className="stylesQuickAddCont">
                        <div id="closeQuickContainer"></div>
                    </div>
                </div>
            )}

            <div
                id="searchIcon"
                className={`secIcon ${isFloorplanPage && !openSearch ? "active" : ""}`}
                onClick={handleSearchClick}
            >
                <div className="signAddWs titleFont"></div>
            </div>
        </div>
    );
};

export default FloatMenu;
