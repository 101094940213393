import React, { useEffect, useState } from "react";

import '../_styles/LegendBar.css';
const LegendBar = ({
  sketchStyle,
  updateBuildBoxes,
  resetBoxes,
  masterSketchRef,
  unitRender,
  floorplan,
  currentProject,
  isLeftExpanded,
  isRightExpanded,
  hideSold,
  styles
}) => {
  const [hideAvailability, setHideAvailability] = useState(false);
  const [switchOn, setSwitchOn] = useState(false);
  const [infoImage, setInfoImage] = useState(true);

  const hideBoxes = () => {
    setHideAvailability(!hideAvailability);
  };

  if (hideAvailability) {
    updateBuildBoxes([], [], sketchStyle);
  }

  if (switchOn && !hideAvailability) {
    resetBoxes();
    setSwitchOn(false);
  }

  useEffect(() => {
    if(infoImage) {
      setTimeout(() => {
        setInfoImage(false);
      }, 7000);
    }
  }, [unitRender, infoImage, isLeftExpanded, hideSold, currentProject]);

  return (
    <>
      <div
        className="legend"
        style={{
          left: isLeftExpanded ? "50%" : "25%",
          display: isRightExpanded ? "none" : "",
        }}
      >
        <div
          className={`hide-availability msg-tooltip w-8 labelIcon parentTooltip ${hideAvailability ? "active" : ""}`}
          data-title="Availability"
          onClick={() => {
            hideBoxes();
            setSwitchOn(true);
          }}
        >
        </div>
        <div
          className="button-action msg-tooltip w-8 resetView mr-2 ml-2"
          data-title="Reset View"
          onClick={() => masterSketchRef.current.handleZoomOut()}
        >
          
        </div>
        <div className="info-color mr-2">
          <span
            className="round-color"
            style={{ background: sketchStyle.available_color }}
          ></span>{" "}
          {floorplan ? floorplan.fp_name : "Available"}
        </div>

        {hideSold === "0" && (
          <div className="info-color">
            <span
              className="round-color"
              style={{
                background: unitRender
                  ? sketchStyle.unit_color
                  : sketchStyle.sold_color,
              }}
            ></span>
            {
              unitRender ? unitRender : (currentProject.data?.saleOrRent === "1" ? "Leased" : "Sold")}
          </div>
        )}

        {hideSold === "1" && (
          <div className="info-color">
            {unitRender && (
              <>
                <span
                  className="round-color"
                  style={{ background: sketchStyle.unit_color }}
                ></span>
                {unitRender}
              </>
            )}
          </div>
        )}
        <div
          className="button-action msg-tooltip w-8 navHelp parentTooltip ml-2 mr-2"
          data-title="Navigation Help"
          onClick={() => setInfoImage(!infoImage)}
        >
        </div>
      </div>

      <div
        className={`help-guide absolute ${infoImage ? "show" : "hide"}`}
        style={{
          background: `url(${currentProject?.info?.controls_navigation_desk})`,
        }}
      ></div>
    </>
  );
};

export default LegendBar;
