/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState, useRef } from "react";
import _ from "lodash";
import PanoramaView from "./PanoramaView";
import RequestInfoForm from "./RequestInfoForm";
import { isMobile } from "react-device-detect";
import IframeLightbox from "../../_Parts/LightBox/iframe";
import "../_styles/SelectedFloorplanSlide.css";

const SelectedFloorplanSlide = ({
  floorplan,
  onClose,
  unitRender,
  setSelectedUnitRender,
  isFavorite,
  floorUnit,
  pointForUnit,
  getFloorplate,
  setPointUnit,
  styleProject,
  toggleFavorite,
  units,
  masterSketchRef,
  views,
  activeComparation,
  filterBarRef,
  contactEmail,
  floorsFilter,
  externalForm,
  sketchStyle,
  displayPrices,
  isActiveComparation
}) => {
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [othersUnits, setOthersUnits] = useState([]);
  const [unitOri, setUnitOri] = useState("");
  const [selectedView, setSelectedView] = useState(null);
  const [showPanorama, setShowPanorama] = useState(false);
  const [isComparation, setIsComparation] = useState(false);
  const [btnReqInfo, setBtnReqInfo] = useState({});
  const [compareBtn, setCompareBtn] = useState(false);
  const [showSlider, setShowSlider] = useState(true);
  const openModal = () => setShowRequestModal(true);
  const closeModal = () => setShowRequestModal(false);

  /**
   * Filters and sorts units based on the given floorplan ID and their availability status.
   *
   * @param {number} floorplanId - The ID of the floorplan to filter units by.
   * @returns {void} This function does not return a value. It sets the state with the filtered and sorted units.
   */
  const getOthersUnits = (floorplanId) => {
    let othersUnits = units.filter(
      (unit) =>
        unit.fp_id === floorplanId &&
        unit.unit_status === "available" &&
        (filterBarRef.current.oriFilter !== ""
          ? unit.unit_orientation === filterBarRef.current.oriFilter
          : true) &&
        (!floorsFilter || floorsFilter.includes(unit.unit_floor))
    );

    let orderUnits = othersUnits.sort((a, b) =>
      parseInt(a.unit_name) > parseInt(b.unit_name) ? 1 : -1
    );
    setOthersUnits(orderUnits);
  };

  /**
   * Handles the change of the selected unit.
   *
   * This function is memoized using `useCallback` to prevent unnecessary re-renders.
   * It updates the selected unit render and orientation based on the provided unit data.
   *
   * @param {Object} unit - The unit object containing details about the selected unit.
   * @param {string} unit.unit_model_name - The model name of the unit.
   * @param {string} unit.unit_number - The number of the unit.
   * @param {string} unit.unit_orientation - The orientation of the unit.
   * @returns {Promise<void>} - A promise that resolves when the unit change handling is complete.
   */
  const handleUnitChange = useCallback(
    async (unit) => {
      if (masterSketchRef.current) {
        const unitObj = JSON.parse(unit);
        await masterSketchRef.current.filterByFloorplanId(
          floorplan.fp_id,
          unitObj.unit_model_name,
          false,
          floorsFilter
        );

        const point = await getFloorplate(unitObj.unit_id);

        setSelectedUnitRender(unitObj.unit_number);
        setUnitOri(unit.unit_orientation);
        setPointUnit(point);
      }
    },
    [masterSketchRef, floorplan.fp_id]
  );

  /**
   * Sets the selected view based on the current floor unit and displays the panorama.
   *
   * This function searches through the available views to find one that includes the specified floor unit.
   * Once found, it sets the selected view and enables the panorama display.
   *
   * @function
   */
  const setView = () => {
    const view = views.find((view) => view.floors.includes(floorUnit));
    setSelectedView(view);
    setShowPanorama(true);
  };

  useEffect(() => {
    setShowSlider(true);
    setBtnReqInfo({
      bg: styleProject.requestInfo?.find(
        (item) => item["at"] === ".innerRequestInfo"
      )?.["background-color"],
      color: styleProject.requestInfo?.find(
        (item) => item["at"] === ".innerRequestInfo"
      )?.color,
    });
    getOthersUnits(floorplan.fp_id);
  }, [floorplan, unitOri, pointForUnit, styleProject]);



  return (
    <div
      className={`selected-floorplan-slide ${showSlider ? "show" : "hide"}`}
        style={{
          boxShadow: isComparation
          ? "-9px 0px 13px -3px rgb(233 233 233 / 75%)"
          : "",
      }}
    >
      <button className="close-btn closeView" id="close-floorplan" onClick={onClose}>
      </button>

      <div id="floorplanImagePreview" className="floorplanImageView floorplan-img">
        <img
          src={floorplan.fp_image_med[0]}
          alt={floorplan.fp_name}
          className="full-size-image"
        />
        {!isComparation && !isActiveComparation && (
          <div className="fpFull"></div>
        )}
        

      </div>
     
      <div
        className="pb-5 flex align-items-center justify-between"
        style={{ alignItems: "center" }}
      >
        <div className="floorplate-img relative" style={{ maxWidth: "160px" }}>
          {pointForUnit && pointForUnit.point && (
            <>
              <div
                className={`unit-float pointUnit`}
                style={{
                  top: `${pointForUnit.point.point_position_y * 1.06}%`,
                  left: `${pointForUnit.point.point_position_x * 1.06}%`,
                  border: `none`,
                  backgroundColor: sketchStyle.unit_color,
                  color: styleProject.search?.[1].color,
                }}
              >
                {unitRender}
              </div>
              <img src={pointForUnit.floorplate_image} width="100%" />
            </>
          )}
        </div>

        <div className="info-floorplan px-4 py-4 w-full">
          <div className="flex justify-between">
            <div className="title-big-floorplan">
              <div
                className="fav-button flex items-center"
                onClick={(event) => {
                  event.stopPropagation();
                  toggleFavorite();
                }}
              >
                <div
                  className="fav-icon"
                  style={
                    !isFavorite
                      ? {
                          backgroundImage: `url(${styleProject.favourites?.["background-image"]})`,
                        }
                      : {
                          backgroundImage: `url(${styleProject.favourites?.["background-image_active"]})`,
                        }
                  }
                ></div>

                <div className="flex-col" style={{ lineHeight: "10px" }}>
                  <h2 className="text-3xl">{floorplan.fp_name.toUpperCase()}</h2>
                  <div className="text-sm flex items-center" style={{marginTop: "-5px"}}>
                    <small>UNIT </small>
                    <div className="text-lg ml-1 floorplanPrice" style={{ fontWeight: "100" }}>
                      {unitRender}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center align-middle justify-between">
              {floorplan.fp_price !== "0" && displayPrices !== "0" && (
                <div className="text-sm ml-3 flex items-center floorplanPrice floorplanPricePreview">
                  <span className="floorplanPriceFeaturePreview smallFeatureTitle">FROM:</span>
                  <div className="text-2xl ml-1">
                      {parseInt(floorplan.fp_price).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}{" "}
                  / mth
                  </div>
                  
                </div>
              )}

              {!isComparation && !isActiveComparation ? (
                <>
                  {floorplan.fp_360?.show && floorplan.fp_360?.url !== "" && (
                    <div
                      className="360-button msg-tooltip"
                      onClick={(event) => {
                        event.stopPropagation();
                        // toogleWorksheets();
                      }}
                      data-title="View 360"
                    >
                      <IframeLightbox
                        iframeSrc={floorplan.fp_360.url}
                        buttonText=""
                        stylesBtn={{
                          backgroundImage: `url(${styleProject.show360?.["background-image"]})`,
                        }}
                        className="tour360-button relative"
                        typeStyle="format"
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="returnToList closeFormsBgDark" onClick={() => setShowSlider(false)}></div>
              )}
            </div>
          </div>

          <div className={`grid-floorplan-description flex text-center`}>
            <div className="flex-1">
              <span className="block text-xs">BEDS</span>
              <small className="block text-gray-600 text-xs">
                {floorplan.fp_bedrooms}
              </small>
            </div>
            <div className="flex-1">
              <span className="block text-xs">BATHS</span>
              <small className="block text-gray-600 text-xs">
                {floorplan.fp_bathrooms}
              </small>
            </div>
            <div className="flex-1">
              <span className="block text-xs">
                AREA <br></br> (SQ.FT.)
              </span>
              <small className="block text-gray-600 text-xs">
                {floorplan.fp_size}
              </small>
            </div>
            <div className="flex-1">
              <span className="block text-xs">FLOOR</span>
              <small className="block text-gray-600 text-xs">
                {floorUnit ? floorUnit : floorplan.fp_floors_text?.toString()}
              </small>
            </div>
            <div className="flex-1">
              <span className="block text-xs">EXPOSURE</span>
              <small className="block text-gray-600 text-xs">
                {unitOri
                  ? unitOri
                  : floorplan.fp_orientations?.fp_orientation?.toString()}
              </small>
            </div>

            <div className="flex">
              <select
                className="ml-2 form-select-floorplan text-xs p-1 viewDropdown"
                onChange={(e) => handleUnitChange(e.target.value)}
              >
                <option value="Select Unit">Other Units</option>
                {othersUnits &&
                  othersUnits.sort().map(
                    (unit) =>
                      unit.unit_number !== unitRender && (
                        <option key={unit.unit_id} value={JSON.stringify(unit)}>
                          {unit.unit_number}
                        </option>
                      )
                  )}
                ;
              </select>
            </div>
            <div className="flex">
              {!isComparation && views !== undefined && !isActiveComparation &&(
                <button
                  className="btn btn-primary square-button ml-2 w-auto items-center justify-center "
                  style={{ width: "100%" }}
                  onClick={() => setView()}
                >
                  VIEWS
                </button>
              )}
            </div>
          </div>

          <div className="mt-4 flex buttons-group">
            <a
              className="btn btn-primary square-button big-btn downloadFloorplan downloadText"
              href={floorplan.fp_pdf}
              target="_blank"
            >
              Download Floorplan
            </a>
            {!externalForm?.url ? (
              <button
                className="btn btn-primary square-button black ml-2 big-btn requestInfoFllorplan innerRequestInfo"
                data-modal-target="request-info"
                data-modal-toggle="request-info"
                style={{
                  backgroundColor: btnReqInfo.bg,
                  color: btnReqInfo.color,
                }}
                onClick={openModal}
              >
                Request Information
              </button>
            ) : (
              <IframeLightbox
                iframeSrc={externalForm.url}
                buttonText={externalForm.text}
                stylesBtn={{
                
                }}
                className="btn btn-primary square-button black ml-2 big-btn requestInfoFllorplan innerRequestInfo"
              />
            )}

            {!isMobile && (
              <button
                className="btn btn-primary square-button ml-2 w-auto items-center justify-center border-none innersubmit_ws"
                style={{ 
                  display: isComparation || isActiveComparation ? "none" : "block"}}
                onClick={() => {
                  activeComparation();
                  setIsComparation(true);
                }}
              >
                COMPARE
              </button>
            )}
          </div>
        </div>
      </div>
      {selectedView && showPanorama && (
        <PanoramaView
          data={selectedView}
          onClosePanorama={() => setShowPanorama(false)}
        />
      )}
      {showRequestModal && (
        <RequestInfoForm
          closeModal={closeModal}
          floorplan={floorplan}
          contactEmail={contactEmail}
          unitFloor={floorUnit}
        />
      )}
    </div>
  );
};

export default SelectedFloorplanSlide;
