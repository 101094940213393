/**
 * Converts a hyphen-separated string to camelCase.
 *
 * @param {string} str - The string to be converted.
 * @returns {string} - The camelCase formatted string.
 */
const formatStringToCamelCase = (str) => {
  const splitted = str.split("-");
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join("")
  );
};

/**
 * Converts a CSS style string into a JavaScript style object.
 *
 * @param {string} str - The CSS style string to convert.
 * @returns {Object} - The JavaScript style object.
 */
export const getStyleObjectFromString = (str) => {
  let styles = [];
  for (const [key, value] of Object.entries(str)) {
    styles.push(`${key}: ${value}`);
  }
  str = styles.join(";");
  const style = {};
  str.split(";").forEach((el) => {
    const [property, value] = el.split(":");
    if (!property) return;

    const formattedProperty = formatStringToCamelCase(property.trim());
    style[formattedProperty] = value.trim();
  });

  return style;
};

/**
 * Formats an object of CSS styles into a camelCase JavaScript object.
 *
 * @param {Object} obj_str - The object containing CSS styles as key-value pairs.
 * @param {any} [at=null] - An optional parameter that is not used in the function.
 * @returns {Object} - A new object with camelCase property names and formatted values.
 *
 * @example
 * const styles = formatStyles({
 *   'background-color': 'red',
 *   'font-size': '16px',
 *   'background-image': 'http://example.com/image.png'
 * });
 * // styles = {
 * //   backgroundColor: 'red',
 * //   fontSize: '16px',
 * //   backgroundImage: 'url("http://example.com/image.png")'
 * // }
 */
export const formatStyles = (obj_str, at = null) => {
  const styles = {};
  
  if(obj_str?.at){
    delete obj_str.at
  }
  if(!obj_str || Object.entries(obj_str).length < 1) return {};

  for(const [prop, value] of Object.entries(obj_str)){
    const checkValue = (value.trim().toLowerCase().startsWith('http')) ? `url("${value}")` : value;
    styles[formatStringToCamelCase(prop.trim())] = checkValue;
  }

  return styles;
};

/**
 * Converts a given data object or array of objects into CSS styles.
 *
 * @param {Object|Object[]} data - The input data containing style definitions.
 * @param {string} data[].at - The CSS selector for the styles.
 * @param {Object} data[].styles - The styles to be applied.
 * @param {string} [data[].styles._active] - The styles to be applied when the element is active.
 * @param {string} [data[].styles._<resolution>] - The styles to be applied at specific resolutions.
 * @param {string} [data[].styles.background-image] - The background image URL.
 * @returns {string} The generated CSS styles.
 */
export const convertToCSS = (data) => {
  const mediaQueries = {};
  let baseStyles = "";

  // Normalizar la entrada a un array, incluso si es un solo objeto
  const dataArray = Array.isArray(data) ? data : [data];

  dataArray.forEach((item) => {
    const { at, ...styles } = item;
    let baseStyle = `${at} { `;
    let activeStyle = `${at}.active { `;

    Object.entries(styles).forEach(([key, value]) => {
      const isActive = key.toLowerCase().includes("_active");
      const resolutionMatch = key.match(/_(\d+|full)$/);
      const resolution = resolutionMatch ? resolutionMatch[1] : null;

      // Si es `_active`, priorizamos su procesamiento
      if (isActive) {
        const property = key.replace("_active", "");
        const formattedValue =
          property.toLowerCase() === "background-image"
            ? value.startsWith("url(")
              ? value
              : `url("${value}")` 
            : value || "default";

        activeStyle += `${property}: ${formattedValue}; `;
        return; // Evitamos procesarlo de nuevo como una propiedad estándar o con resolución
      }

      // Si tiene una resolución específica
      if (resolution) {
        if (!mediaQueries[resolution]) {
          mediaQueries[resolution] = [];
        }

        const property = key.replace(/_\d+|_full/, "");
        const formattedValue =
          property.toLowerCase() === "background-image"
            ? value.startsWith("url(")
              ? value
              : `url("${value}")`
            : value || "default";

        const rule = `${at} { ${property}: ${formattedValue}; }`;
        mediaQueries[resolution].push(rule);
        return;
      }

      // Procesar propiedades estándar
      const property = key;
      const formattedValue =
        property.toLowerCase() === "background-image"
          ? value.startsWith("url(")
            ? value
            : `url("${value}")`
          : value || "default";

      baseStyle += `${property}: ${formattedValue}; `;
    });

    baseStyle += "} ";
    activeStyle += "} ";
    baseStyles += baseStyle + activeStyle;
  });

  // Generar las reglas de media queries
  let mediaStyles = "";
  Object.entries(mediaQueries).forEach(([size, rules]) => {
    if (size === "full") {
      mediaStyles += `${rules.join("\n  ")}\n`;
    } else {
      mediaStyles += `@media screen and (max-width: ${size}px) {\n  ${rules.join("\n  ")}\n}\n`;
    }
  });

  // Combinar todo
  return `\n${baseStyles}\n${mediaStyles}`;
};





