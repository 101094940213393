import React, { useEffect } from 'react';
import '../_styles/DetailsFloorplan.css';

const DetailsFloorplan = ({ floorplan, floorUnit, isRowFloorplan, isSelected, unitOri, buttons }) => {

    useEffect(() => {
    }, [ unitOri]);
    
    return (
        <div className={`grid-floorplan-description flex space-x-3 text-center ${isRowFloorplan && !isSelected ? 'w-full' : ''}`}>
            <div className="flex-1">
                <span className="block text-xs">BEDS</span>
                <small className="block text-gray-600 text-xs">
                {floorplan.fp_bedrooms}
                </small>
            </div>
            <div className="flex-1">
                <span className="block text-xs">BATHS</span>
                <small className="block text-gray-600 text-xs">
                {floorplan.fp_bathrooms}
                </small>
            </div>
            <div className="flex-1">
                <span className="block text-xs">AREA</span>
                <small className="block text-gray-600 text-xs">
                {floorplan.fp_size} 
                </small>
            </div>
            <div className="flex-1">
                <span className="block text-xs">FLOOR</span>
                <small className="block text-gray-600 text-xs">
                    {floorUnit ? floorUnit : floorplan.fp_floors_text?.toString()}
                </small>
            </div>
            <div className="flex-1">
                <span className="block text-xs">EXPOSURE</span>
                <small className="block text-gray-600 text-xs">
                {unitOri ? unitOri : floorplan.fp_orientations?.fp_orientation?.toString()}
                </small>
            </div>
        </div>
    );
};

export default DetailsFloorplan;