/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import RequestInfoForm from "./RequestInfoForm";
import IframeLightbox from "../../_Parts/LightBox/iframe";
import '../_styles/CompareFloorplanSlide.css';

const CompareFloorplanSlide = ({
  compareFloorplan,
  compareUnitRender,
  isFavorite,
  floorUnit,
  pointForUnit,
  styleProject,
  toggleFavorite,
  unitCompareFloorplan,
  contactEmail,
  sketchStyle,
  displayPrices,
  externalForm,
  btnReqInfo,
}) => {

  const [showRequestModal, setShowRequestModal] = useState(false);
  const [unitOri, setUnitOri] = useState("");
  const [showSlider, setShowSlider] = useState(true);
  const openModal = () => setShowRequestModal(true);
  const closeModal = () => setShowRequestModal(false);


  useEffect(() => { 
    setShowSlider(true);
   }, [compareFloorplan, unitOri]);

  return (
    <div className={`compared-floorplan-slide ${showSlider ? "show" : "hide"}`}>
      
      <div id="floorplanImagePreview" className="floorplanImageView floorplan-img">
        <img
          src={compareFloorplan.fp_image_med[0]}
          alt={compareFloorplan.fp_name}
          className="full-size-image"
        />
       

      </div>

      <div  className="pb-5 flex align-items-center justify-between"
        style={{ alignItems: "center" }}
        >
          <div className="floorplate-img relative" style={{ maxWidth: "160px" }}>
            {pointForUnit && pointForUnit.point && (
              <>
                 <div
                className={`unit-float pointUnit`}
                style={{
                  top: `${pointForUnit.point.point_position_y * 1.06}%`,
                  left: `${pointForUnit.point.point_position_x * 1.06}%`,
                  border: `none`,
                  backgroundColor: sketchStyle.unit_color,
                  color: styleProject.search?.[1].color,
                }}
              >
                {compareUnitRender}
              </div>
                <img src={pointForUnit.floorplate_image} width="100%" />
              </>
            )}
          </div>

          <div className="info-floorplan px-4 py-4 w-full">
          <div className="flex justify-between">
            <div className="title-big-floorplan">
              <div
                className="fav-button flex items-center"
                onClick={(event) => {
                  event.stopPropagation();
                  toggleFavorite();
                }}
              >
                <div
                  className="fav-icon"
                  style={
                    !isFavorite
                      ? {
                          backgroundImage: `url(${styleProject.favourites?.["background-image"]})`,
                        }
                      : {
                          backgroundImage: `url(${styleProject.favourites?.["background-image_active"]})`,
                        }
                  }
                ></div>

                <div className="flex-col" style={{ lineHeight: "10px" }}>
                  <h2 className="text-3xl">{compareFloorplan.fp_name.toUpperCase()}</h2>
                  <div className="text-sm flex items-center" style={{marginTop: "-5px"}}>
                    <small>UNIT </small>
                    <div className="text-lg ml-1 floorplanPrice" style={{ fontWeight: "100" }}>
                      {unitCompareFloorplan}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center align-middle justify-between">
              {compareFloorplan.fp_price !== "0" && displayPrices !== "0" && (
                <div className="text-sm ml-3 flex items-center floorplanPrice floorplanPricePreview">
                  <span className="floorplanPriceFeaturePreview smallFeatureTitle">FROM:</span>
                  <div className="text-2xl ml-1">
                      {parseInt(compareFloorplan.fp_price).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}{" "}
                  / mth
                  </div>
                  
                </div>
              )}

              <div className="returnToList closeFormsBgDark" onClick={() => setShowSlider(false)}></div>
            </div>
          </div>

          <div className={`grid-floorplan-description flex text-center`}>
            <div className="flex-1">
              <span className="block text-xs">BEDS</span>
              <small className="block text-gray-600 text-xs">
                {compareFloorplan.fp_bedrooms}
              </small>
            </div>
            <div className="flex-1">
              <span className="block text-xs">BATHS</span>
              <small className="block text-gray-600 text-xs">
                {compareFloorplan.fp_bathrooms}
              </small>
            </div>
            <div className="flex-1">
              <span className="block text-xs">
                AREA <br></br> (SQ.FT.)
              </span>
              <small className="block text-gray-600 text-xs">
                {compareFloorplan.fp_size}
              </small>
            </div>
            <div className="flex-1">
              <span className="block text-xs">FLOOR</span>
              <small className="block text-gray-600 text-xs">
                {floorUnit ? floorUnit : compareFloorplan.fp_floors_text?.toString()}
              </small>
            </div>
            <div className="flex-1">
              <span className="block text-xs">EXPOSURE</span>
              <small className="block text-gray-600 text-xs">
                {unitOri
                  ? unitOri
                  : compareFloorplan.fp_orientations?.fp_orientation?.toString()}
              </small>
            </div>
          </div>

          <div className="mt-4 flex buttons-group">
            <a
              className="btn btn-primary square-button big-btn downloadFloorplan downloadText"
              href={compareFloorplan.fp_pdf}
              target="_blank"
            >
              Download Floorplan
            </a>
            {!externalForm?.url ? (
              <button
                className="btn btn-primary square-button black ml-2 big-btn requestInfoFllorplan innerRequestInfo"
                data-modal-target="request-info"
                data-modal-toggle="request-info"
                style={{
                  backgroundColor: btnReqInfo?.bg,
                  color: btnReqInfo?.color,
                }}
                onClick={openModal}
              >
                Request Information
              </button>
            ) : (
              <IframeLightbox
                iframeSrc={externalForm.url}
                buttonText={externalForm.text}
                stylesBtn={{
                
                }}
                className="btn btn-primary square-button black ml-2 big-btn requestInfoFllorplan innerRequestInfo"
              />
            )}
          </div>
        </div>
        </div>
      {showRequestModal && (
        <RequestInfoForm closeModal={closeModal} flooplan={compareFloorplan} contactEmail={contactEmail} unitFloor={unitCompareFloorplan}/>
      )}
    </div>
  );
};

export default CompareFloorplanSlide;
