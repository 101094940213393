/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import slugify from "react-slugify";
import { formatStyles, getStyleObjectFromString } from "../../../../plugins/FormatStyle.js";
import { getProjectById } from "../../../../db.js";
import "./gallery.css";

const FullScreenSlider = ({ pattern, content, colors, sugpage, section }) => {
  const { projectId } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sectionsPage, setSectionsPage] = useState([]);

  useEffect(() => {
    console.log(pattern)
    const getProject = async () => {
      const project = await getProjectById(projectId);
      setSectionsPage(project.content.section);
    };

    getProject();
    console.log(sectionsPage)
  }, [projectId]);

  const slides = useMemo(() => {
    return Array.isArray(content?.floatSquare_item)
      ? content.floatSquare_item
      : [content.floatSquare_item];
  }, [content]);

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  // Calcula los estilos una sola vez
  const textStyle = useMemo(
    () => getStyleObjectFromString(colors?.textColor_0_0 || ""),
    [colors]
  );

  const titleStyle = useMemo(
    () =>
      getStyleObjectFromString({
        ...colors?.textColor_0_0,
        ...colors?.titleColor_1_0,
        ...colors?.titleColor_2_0,
        ...colors?.title,
      }),
    [colors]
  );

  const subtitleStyle = useMemo(
    () =>
      formatStyles(
        colors?.subtitleColor_0_0 || colors?.subtitleColor_1_0
      ),
    [colors]
  );

  const ctaStyle = useMemo(
    () =>
      getStyleObjectFromString({
        ...colors?.ctaColor_0_0,
        ...colors?.ctaColor_1_0,
      }),
    [colors]
  );

  const linkButtonSection = (sectionId, indexSub, sugpage) => {
    const section = sectionsPage?.find((item) => item.id === parseInt(sectionId));
    const subsection = section?.subsection?.find((item) => item.indexSubsection === parseInt(indexSub));
    return !sugpage ? slugify(section?.title || '') : slugify(subsection?.titleSubsection || '');
  };

  const RenderSlideContent = (item) => {
    const {
      floatSquare_title: title,
      floatSquare_subtitle: subtitle,
      floatSquare_text: text,
      floatSquare_button: button,
      logo,
    } = item.floatSquare_content;

    

    return (
      <div id="float__square">
      <div
        className={`float__square__content text-slide absolute flex-wrap txtBgColor_${pattern}_${content.indexSubsection} boxOpacity ${
          item?.textboxPosition && item?.textboxPosition !== "Array"
            ? item?.textboxPosition.toLowerCase()
            : "left"
        }`}
        style={{
          ...(item.textboxbg
            ? {
                backgroundImage: `url(${item.textboxbg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: colors?.opacitytxbx[0].opacity  || 1,
              }
            : {}),
        }}
      >
        {logo?.urlMedium && !title ? (
          <img
            className={`half__gallery__text-topImage topImage-undefined logo-slide ${item.floatSquare_content.floatSquare_logo_align}`}
            src={logo.urlMedium}
            alt="Logo"
          />
        ) : (
          <>
            <h2 className={`float__square__content-title titleFont titleColor_${pattern}_${content.indexSubsection}`}
           
            >
              <p  dangerouslySetInnerHTML={{ __html: title }}></p>
            </h2>
            <h5 className={`float__square__content-subtitle subtitleFont subtitleColor_${pattern}_${content.indexSubsection}`} dangerouslySetInnerHTML={{ __html: subtitle }}></h5>
          </>
        )}

        {text && (
          <p className={`float__square__content-text textColor_${pattern}_${content.indexSubsection}`} style={textStyle}>
            {text}
          </p>
        )}

        {typeof button?.["floatSquare_button-title"]["value"] === "string" && (
          <Link
            to={`/project/${projectId}${
              sugpage ? `/${sugpage}/` : "/"
            }${(linkButtonSection(button["floatSquare_button-title"]["section"], button["floatSquare_button-title"]["subsection"], sugpage))}`}
            className={`float__square__content-cta content_cta ctaColor_${pattern}_${content.indexSubsection}`}
          >
            {button["floatSquare_button-title"]["value"]}
          </Link>
        )}

        {slides.length > 1 && (
          <div className="arrows absolute bottom-0 right-0">
            <button
              onClick={prevSlide}
              className={`arrow bottom-0 right-12 text-white arrowLeft_${pattern}_${content.indexSubsection}`}
              aria-label="Previous Slide"
            >
            </button>

            <button
              onClick={nextSlide}
              className={`arrow bottom-0 right-0 text-white arrowRight_${pattern}_${content.indexSubsection}`}
              aria-label="Next Slide"
            >
            </button>
          </div>
        )}
      </div>
      </div>

    );
  };

  return (
    <div className="w-full h-screen overflow-hidden relative">
      {slides.length > 0 &&
        slides.map((item, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              index === currentSlide ? "opacity-100" : "opacity-0"
            }`}
            style={{
              backgroundImage: `url(${item.floatSquare_bg})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            {RenderSlideContent(item)}
          </div>
        ))}


    </div>
  );
};

export default FullScreenSlider;
