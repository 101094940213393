import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../header";
import Menu from "../Templates/_Parts/Menu";
import Logo from "../Templates/_Parts/Logo";
import ContactHeader from "../Templates/_Parts/ContactHeader";
import PageContent from "../PageContent";
import Subpage from "../SinglePage";
import { getStyleObjectFromString } from "../../plugins/FormatStyle";
import slugify from "react-slugify";
import "./Project.css";
const Project = ({ project }) => {
  if (!project) return null;

  return (
    <div>
      <Header fonts={project.fonts?.font} />
      <Menu
        styles={project.styles}
        bgMenu={project.content?.bg_image_menu}
        content={project.content}
        logoMenu={project.project_data?.logo_main_menu}
      />
      <Logo img={project.content?.logo} />
      <ContactHeader email={project.content?.contact_mail} />
      <div
        id="allContainer"
        style={getStyleObjectFromString(project.styles.allContent)}
      >
        <Routes>
          {project.content?.section.map((page) => {
            const pageSlug = slugify(page.title);
            return (
              <Route
                key={pageSlug}
                path={pageSlug}
                element={
                  <PageContent
                    favorites={project.favorites}
                    page={page}
                    colors={project.styles}
                    projectType={project?.project_data?.project_type}
                    sections={project.content.section}
                    isRent={parseInt(project?.project_data?.saleOrRent)}
                  />
                }
              />
            );
          })}
          <Route
            path=":sectionSlug/:subsectionSlug"
            element={
              <Subpage
                sectionContent={project.content?.section}
                colors={project.styles}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Project;
