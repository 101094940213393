/* eslint-disable no-mixed-operators */
import React, { useState, useEffect, useRef } from "react";
import {
  formatStyles,
  getStyleObjectFromString,
} from "../../../../plugins/FormatStyle.js";
import { Link, useParams } from "react-router-dom";
import slugify from "react-slugify";

import "./menu.css";

const Menu = ({ styles, bgMenu, content, logoMenu }) => {
  const { projectId } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const submenuRefs = useRef([]); // To store references to submenus

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSubmenu = (index) => {
    setActiveSubmenu((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    if (menuOpen) {
      const menuAnimationDuration = 200;
      const itemDelay = 100;

      setTimeout(() => {
        const items = document.querySelectorAll(".menuItem");
        items.forEach((item, index) => {
          setTimeout(() => {
            item.classList.add("show-item");
          }, index * itemDelay);
        });
      }, menuAnimationDuration);
    } else {
      const items = document.querySelectorAll(".menuItem");
      items.forEach((item) => {
        item.classList.remove("show-item");
      });
    }
  }, [menuOpen]);

  useEffect(() => {
    submenuRefs.current.forEach((submenu, index) => {
      if (submenu) {
        submenu.style.maxHeight =
          activeSubmenu === index ? `${submenu.scrollHeight}px` : "0";
      }
    });
  }, [activeSubmenu]);

  const handleItemClick = (e, item) => {
    if (!item.subsection || item.subsection.length <= 1) {
      setMenuOpen(false);
    }
  };

  return (
    <div className="container">
      <div
        id="burguer"
        className={menuOpen ? "active" : ""}
        onClick={toggleMenu}
      >
        <div
          className={`bread ${menuOpen ? "active" : ""}`}
          id="breadTop"
        ></div>
        <div
          className={`bread ${menuOpen ? "active" : ""}`}
          id="breadMiddle"
        ></div>
        <div
          className={`bread ${menuOpen ? "active" : ""}`}
          id="breadBottom"
        ></div>
      </div>
      <img
        src={logoMenu}
        alt=""
        id="logoMainMenu"
        className={`${menuOpen ? "active" : ""}`}
      />
      <div
        className={`animated-menu ${menuOpen ? "open" : ""}  ${
          menuOpen ? "active" : ""
        } overlay bgMenu`}
        style={{
          ...getStyleObjectFromString(styles.mainMenu),
          backgroundImage: `url(${bgMenu})`,
        }}
      >
        <nav className="menu">
          <ul>
            {content &&
              content.section
                .slice()
                .map((item, index) => (
                  <div key={index}>
                    <Link
                      className="menuItem menuItemFont activeHash menuItemInner-0"
                      to={
                        item.subsection && item.subsection.length > 1
                          ? "?type=submenu"
                          : `/project/${projectId}/${slugify(item.title)}`
                      }
                      onClick={(e) => {
                        if (item.subsection && item.subsection.length > 1) {
                          e.preventDefault();
                          toggleSubmenu(index);
                        } else {
                          handleItemClick(e, item);
                          toggleMenu();
                        }
                      }}
                      style={{
                        "--index": index,
                      }}
                    >
                      {item.title}
                      {item.subsection && item.subsection.length > 1 && (
                        <div
                          className={`arrow-down ${
                            activeSubmenu === index ? "rotate" : ""
                          }`}
                          style={{
                            ...(styles.arrowMenu["background-image"]
                              ? {
                                  backgroundImage: `url(${styles.arrowMenu["background-image"]})`,
                                }
                              : {}),
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            toggleSubmenu(index);
                          }}
                        ></div>
                      )}
                    </Link>

                    {/* Submenu */}
                    <div
                      ref={(el) => (submenuRefs.current[index] = el)}
                      className={`submenu ${
                        activeSubmenu === index ? "show" : ""
                      }`}
                    >
                      {item.subsection &&
                        item.subsection.length > 1 &&
                        item.subsection.map((subitem, subIndex) => (
                          <Link
                            key={subIndex}
                            className="submenuItem"
                            to={`/project/${projectId}/${slugify(
                              item.title
                            )}/${slugify(subitem.titleSubsection)}`}
                            onClick={() => setMenuOpen(false)}
                            style={getStyleObjectFromString({
                              ...styles.submenuItem,
                              ...styles.mainSubmenu,
                              ...styles.menuItemFont,
                            })}
                          >
                            {subitem.titleSubsection.replace(/&amp;/g, "&")}
                          </Link>
                        ))}
                    </div>
                  </div>
                ))}
          </ul>
        </nav>

        { content.contact_phone !== "" || content.contact_mail !== "" && (
          <div id="contactMenu" className="contact">
          <div className="contactTitle titleFont">CONTACT US</div>
            {content.contact_phone && (
          <div
            className="contactHalf"
            id="contactHalfPhone"
            style={{ display: "none" }}
          >
              <div className="contactHalfLabel">P:</div>
                <a
                  className="contactHalfContent"
                  id="phoneContent"
                  href={`tel:${content.contact_phone}`}
                >
                  {content.contact_phone}
                </a> 
          </div>
           )}

           {content.contact_mail && (
            <div className="contactHalf" id="contactHalfEmail">
              <div className="contactHalfLabel">E:</div>
                <a
                  className="contactHalfContent"
                  id="emailContent"
                  href={`mailto:${content.contact_mail}}`}
                >
                  {content.contact_mail}
                </a>
            </div>
           )}
          
          
        </div>
        )}
        
        <div id="credit">
				    <a href="https://zentro.vision">
            <img src="https://zentro.s3.us-west-2.amazonaws.com/wp-content/uploads/Zentro_logo_black.png" alt="" width="100" />
          </a>
			    </div>
      </div>
    </div>
  );
};

export default Menu;
