/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './style.css'; // Asegúrate de que este archivo CSS esté en la ubicación correcta

const Slideshow = ({ images, autoplayInterval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imagesArray = images?.image || images;

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, autoplayInterval);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [currentIndex, autoplayInterval]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imagesArray.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="slideshow-container relative w-full h-screen">


    {/* Slide Image */}
    {imagesArray.map((image, index) => {
      // Verificar si el objeto tiene la propiedad 'images'
      const imageObject = image.image || image; // Usa 'images' si existe, de lo contrario usa el objeto directamente

      return (
        <div
          key={index}
          className={`slide ${currentIndex === index ? 'slide-active' : ''}`}
        >
          {imageObject.titleSlideshow && (
            <div className="slide-title">{imageObject.titleSlideshow}</div>
          )}
          <img
            src={imageObject.urlLarge}
            alt={imageObject.titleSlideshow || ''}
            className="w-full h-full"
          />
        </div>
      );
    })}


      {/* Slide Indicator */}
      <div className="indicators">
        {imagesArray.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`indicator ${currentIndex === index ? 'indicator-active' : ''}`}
            aria-label={`Slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
