import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import "./DualRange.css";

const MultiRangeSlider = forwardRef(({ min, max, onMouseUp, colors }, ref) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  // Actualizamos minVal y maxVal cuando cambian las props min y max
  useEffect(() => {
    setMinVal(min);
    setMaxVal(max);
    minValRef.current = min;
    maxValRef.current = max;
  }, [min, max]);

  // Exponemos la función resetSlider al padre
  useImperativeHandle(ref, () => ({
    resetSlider() {
      setMinVal(min);
      setMaxVal(max);
      minValRef.current = min;
      maxValRef.current = max;
    },
  }));

  // Convertir a porcentaje
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Actualizar el estilo del rango del slider según minVal
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Actualizar el estilo del rango del slider según maxVal
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Ejecutar el filtro cada vez que el usuario cambia el slider
  const handleSliderChange = () => {
    onMouseUp({ min: minVal, max: maxVal });
  };

  return (
    <div className="container-slider-dual">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        onMouseUp={handleSliderChange}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5", "--thumb-bg": colors }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        onMouseUp={handleSliderChange}
        className="thumb thumb--right"
        style={{ appearance: "#000", "--thumb-bg": colors }}
      />

      <div className="slider">
        <div className="slider__track noUi-connects" />
        <div ref={range} className="slider__range noUi-connect" />
        <div className="slider__left-value" style={{ color: colors }}>
          {minVal}
        </div>
        <div className="slider__right-value" style={{ color: colors }}>
          {maxVal}
        </div>
      </div>
    </div>
  );
});

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onMouseUp: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
